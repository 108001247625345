import React, {Component} from 'react';
import '../Styles/About.scss';
import * as bg from '../Images/about.jpg';
import * as ritchey from '../Images/MugRitchey.png';
import * as scott from '../Images/MugScott.png';
import * as jay from '../Images/MugJay.png';
import * as jem from '../Images/MugJem.png';
import * as john from '../Images/MugJohn.png';
import Parallax from "../Components/Parallax";
import Fade from 'react-reveal/Fade';

class AboutComponent extends Component {
    render() {
        return (
            <div className="about-container">
                <Parallax img={bg}>
                    <div className="about-company">
                        <h2>About Us</h2>
                        <p>
                        Founded in 2003, Information Technology for Enterprises (IT4E) began its information technology training and 
                        consulting practice in Knoxville, Tennessee with clients located thoughout the United States. . We use our 
                        background in training to share the knowledge that we have with our clients’ IT professionals. In training 
                        and consulting, we have served enterprises on tasks covering a wide range of subjects, technologies and 
                        products. We have recently developed the capability to develop native XSA applications on the SAP HANA 2.0 
                        platform that apply predictive algorithms on data and prescribe actionable results. We also have 
                        substantial experience with relational database technologies, application design and development, 
                        Microsoft SharePoint products and technologies and many others.
                        </p>
                    </div>
                </Parallax>
                <div className="meet-team">
                    <div className="meet-header">
                        <h2>Meet the Team!</h2>
                    </div>
                    <div className="team-member">
                        <div className="info">
                            <h4>Ritchey Hume</h4>
                            <h6>President/Owner</h6>
                            <p>
                            Ritchey is the owner of IT4E and an active consultant working on projects for several of its clients. 
                            He has a Master of Science Degree from the University of Tennessee, with a major in Computer Science, 
                            and he has earned technical certifications from SAP, Microsoft and Caldera (Linux). Ritchey was a 
                            member of the Microsoft Certified Trainer program for 18 years.  He recently earned the SAP Certified 
                            Development Associate – SAP HANA (SPS03) certification. His recent experience includes projects with 
                            SAP HANA and ASP.NET applications that consume data from the SAP ERP systems and Oracle Primavera P6 
                            scheduling systems and provide reports and outputs to other specialized project control systems. He 
                            recently completed three SAP Education courses including: SAP HANA 2.0 SPS03 - Installation and 
                            Administration (HA200), SAP HANA 2.0 SPS03 - Authorizations, Scenarios & Security Requirements (HA240), 
                            and SAP HANA 2.0 SPS03 - Application Development for SAP HANA (HA450).
                            </p>
                        </div>
                        <Fade right>
                            <div className="img">
                                <figure className="avatar avatar-xl">
                                    <img src={ritchey} />
                                </figure>
                            </div>
                        </Fade>
                    </div>
                    <div className="divider primary"/>
                    <div className="team-member">
                        <Fade left>
                            <div className="img">
                                <figure className="avatar avatar-xl">
                                    <img src={scott} />
                                </figure>
                            </div>
                        </Fade>
                        <div className="info">
                            <h4>Scott Hickman</h4>
                            <h6>Senior Systems Developer</h6>
                            <p>
                            Scott is a senior developer who has worked on projects for many IT4E clients and is one of the key 
                            personnel on a long-term project at present.  Scott specializes in web development, application 
                            development, database management, enterprise software, and project management.  Scott’s special 
                            expertise is developing web applications that transform line-of-business data from many enterprise 
                            data sources into interactive dashboards used by senior-level executives. He recently completed two 
                            SAP Education courses, one on the development native SAP HANA 2.0 XSA applications and the other on 
                            dashboard development using SAP Lumira 2.1 Designer.
                            </p>
                        </div>
                    </div>
                    <div className="divider primary"/>
                    <div className="team-member">
                        <div className="info">
                            <h4>Jay Gingrich</h4>
                            <h6>Senior Systems Consultant</h6>
                            <p>
                            Jay is an Information Systems and Technology professional with experience in federal proposal writing, 
                            project and contract management, systems design, development and integration, and customer 
                            relationships. As an existing business owner and consultant, Jay has an extensive background with 
                            business and financial management, human resource information systems and management, software 
                            development, data center, contact center, and facilities operations.Jay has a Master's in 
                            Business Administration (MBA) from the University of Tennessee and he obtained his Bachelor's degree 
                            in Engineering Science and Mechanics (BESM) and Computer Engineering Certification from the Georgia 
                            Institute of Technology, where he also received multiple design innovation awards. Jay completed a 
                            Master’s Certificate program in government contract management from The George Washington University 
                            and is a certified Project Management Professional (PMP).
                            </p>
                        </div>
                        <Fade right>
                            <div className="img">
                                <figure className="avatar avatar-xl">
                                    <img src={jay} />
                                </figure>
                            </div>
                        </Fade>
                    </div>
                    <div className="divider primary"/>
                    <div className="team-member">
                        <Fade left>
                            <div className="img">
                                <figure className="avatar avatar-xl">
                                    <img src={jem} />
                                </figure>
                            </div>
                        </Fade>
                        <div className="info">
                            <h4>Jem Looney</h4>
                            <h6>Senior Systems Consultant</h6>
                            <p>
                            Jem is a senior database consultant who is one of the key personnel on a long term project at present.  
                            Jem specializes in data modeling, dimensional database warehouse design and applications development, 
                            business intelligence dashboard design.  She holds the Certified ScrumMaster certification from Scrum 
                            Alliance, and she leads a Scrum Team on a daily basis that develops data warehouses and BI dashboards 
                            for our client.  She has also developed courseware and presented technical courses in training 
                            centers around the United States. She recently completed 3 SAP Education courses, one an introduction 
                            to SAP HANA 2.0 (SPS03), one on SAP HANA 2.0 (SPS03) Modeling and one on SAP Data Services (ETL system). 
                            Whether in the classroom as a trainer or working with colleagues individually, Jem welcomes all 
                            opportunities to make people and teams successful with their projects and missions. 
                            </p>
                        </div>
                    </div>
                    <div className="divider primary"/>
                    <div className="team-member">
                        <div className="info">
                            <h4>John Bull</h4>
                            <h6>Business Analytics Consultant</h6>
                            <p>
                            John is a graduate from the award-winning Master of Business Analytics program at the University of 
                            Tennessee. John’s experience involves gaining insights from data and turning them into actionable 
                            results, using statistical programming languages, such as R and Python. John has also worked with SAP 
                            HANA in several disciplines, such as business analytics, database management, and application 
                            development. He works with the Predictive Analysis Library and integration with R and the features 
                            in the Extended Application Services Advanced (XSA) to create native HANA applications with an 
                            SAPUI5 user experience. John has built several predictive models to forecast spending, with results in 
                            user friendly dashboards. He recently earned the SAP Certified Application Associate – SAP HANA 
                            (SPS03) certification.
                            </p>
                        </div>
                        <Fade right>
                            <div className="img">
                                <figure className="avatar avatar-xl">
                                    <img src={john} />
                                </figure>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutComponent;
