import React, {Component} from 'react';
import '../Styles/Footer.scss'

class FooterComponent extends Component {
    render() {
        return (
            <footer className="navbar footer">
                <section className="navbar-section">
                </section>
                <section className="navbar-center">
                    <span>©2003-2020 Information Technology for Enterprises. All Rights Reserved.</span>
                </section>
                <section className="navbar-section">
                </section>
            </footer>
        );
    }
}

export default FooterComponent;
