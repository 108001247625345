import React from 'react';
import {Controller, Scene} from 'react-scrollmagic';
import {Timeline, Tween} from 'react-gsap';

import '../Styles/ParallaxBanner.scss';

const Parallax = ({img, children}) => {
    return (
        <Controller>
            <Scene
                indicators={false}
                duration="200%"
                triggerHook="onEnter">
                <Timeline
                    wrapper={<div className="parallax"/>}>
                    <Tween
                        position="0"
                        from={{
                            yPercent: -50,
                        }}
                        to={{
                            yPercent: 0,
                        }}>
                        <img src={img} alt=""/>
                        {React.Children.toArray(children).filter(o => o)}
                    </Tween>
                    <Tween
                        position="0"
                        from={{
                            top: '0%',
                            scale: 1.5,
                        }}
                        to={{
                            top: '70%',
                            scale: 2,
                        }}>
                    </Tween>
                </Timeline>
            </Scene>
        </Controller>
    );
};

export default Parallax;
