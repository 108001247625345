import React, {Component} from 'react';
import ContactForm from "../Components/ContactForm";
import '../Styles/Contact.scss'
import BgImg from '../Images/contact.jpg';
import Parallax from "../Components/Parallax";
import ContactInformation from "../Components/ContactInformation";

class ContactComponent extends Component {
    iframe = () => {
        const frame = `<iframe src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=123%20Center%20Park%20Drive%2C+(Information%20Technology%20for%20Enterprises)&amp;ie=UTF8&amp;t=&amp;z=17&amp;iwloc=A&amp;output=embed"
                            frameBorder="0" style="border:0" allowFullScreen/>`;

        return {
            __html: frame
        }
    };

    render() {
        return (
            <div className="container">
                <div className="contact-columns">
                    <div className="contact-column contact-image">
                        <Parallax img={BgImg}/>
                    </div>
                    <div className="contact-column contact-column--form">
                        <div className="contact-form-container">
                            <div className="contact-header">
                                <h2>Contact Us</h2>
                                <p>Got a question? We would love to hear from you. Send us a message and we will respond
                                    as soon as possible.</p>
                            </div>
                            <ContactForm sendTo="info@it4e.com"/>
                        </div>
                    </div>
                </div>
                <div className="contact-columns">
                    <div className="contact-column contact-column--location">
                        <ContactInformation location={true}/>
                    </div>
                    <div className="contact-column contact-column--map">
                        <div dangerouslySetInnerHTML={this.iframe()}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactComponent;
