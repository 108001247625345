import React, {Component} from 'react';
import '../../Styles/Solutions.scss';
import bg from '../../Images/databaseManagementBackground.png';
import Parallax from "../../Components/Parallax";
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class DatabaseManagementComponent extends Component {
    render() {
        return (
            <div className="solution-container">
                <Parallax img={bg}>
                    <div className="solution-bar">
                        <h2>Database Management</h2>
                        Most members of the IT4E team have very strong backgrounds and many years of experience 
                        with database management systems and database applications.  In recent years most of our 
                        projects have utilized the SAP HANA, Oracle and Microsoft SQL Server systems. 
                        We work on projects involving both online transaction processing (OLTP) and online analytical 
                        processing (OLAP).  The roles assigned to us on projects range from planning and design to 
                        code development and testing to installation and administration.  Below is a brief summary of 
                        a couple of our recent database projects and a comment on our database training history. 
                    </div>
                </Parallax>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="code" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Application Development</span><br />
                            One of our recent projects involves building dimensional data warehouses which become the 
                            data service tier for dashboard applications.  Source data comes from the SAP ERP system 
                            and from other Oracle databases, and the data warehouse is implemented in Oracle.  We 
                            performed analysis and discovery tasks to identify source data, and procedures were 
                            developed, using both SAP Data Services and PL/SQL, to cleanse, transform and load it 
                            into the data warehouse on a recurring schedule.<br /><br />
                            Another recent project involved building a high performance platform using SAP HANA on 
                            which data scientists built business analytics applications.  Business subject areas in 
                            the scope of this project included supply chain management, employee attrition analysis 
                            and predictive maintenance.  Required data from many enterprise sources was loaded into 
                            SAP HANA using virtual table definitions, SAP Data Services and other extract, transform 
                            and load (ETL) methods.  Transformations implemented in SAP DATA Services and SAP HANA 
                            SQLScript provided data cleansing and validation.  Procedures in the SAP HANA Predictive 
                            Analysis Library (PAL) were called to perform analytic algorithms, such as Auto Regressive 
                            Integrated Moving Average (ARIMA) and Logistic Regression, and the results were used to 
                            produce reports and insights needed to solve business problems.   
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Training</span><br />
                            In 2019, the members of the IT4E consulting team attended several weeks of formal training 
                            presented by SAP Education that focused on SAP HANA and related topics.  The topics included 
                            modeling, native application development, administration, security and SAP Lumira.  We plan 
                            to continue this rigorous training program in 2020 to ensure that IT4E consultants are 
                            always properly prepared and qualified to provide maximum value to our clients.<br /><br />
                            Finally, as a Microsoft Certified Trainer, Ritchey Hume taught Microsoft Official Curriculum 
                            courses covering application development and administration of Microsoft SQL Server for 
                            several years.  From its roots in training, the entire team happily shares as much of its 
                            knowledge about database technology and all other topics as its clients are interested in 
                            receiving. 
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="chalkboard-teacher" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DatabaseManagementComponent;
