import React, {Component} from 'react';
import '../../Styles/Solutions.scss';
import bg from '../../Images/projectManagementBackground.png';
import Parallax from "../../Components/Parallax";
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ProjectManagementComponent extends Component {
    render() {
        return (
            <div className="solution-container">
                <Parallax img={bg}>
                    <div className="solution-bar">
                        <h2>Project Management</h2>
                        IT4E has seventeen years' worth of experience serving clients with a strong history of managing 
                        and successfully completing projects and tasks orders. Through the years, a large portion 
                        of our client base is repeat business with clients whom we have served well and 
                        established long-term relationships.
                    </div>
                </Parallax>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="project-diagram" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Our method</span><br />
                            IT4E offers experienced resources to meet the specific technology qualifications for each 
                            project/task order. Therefore, we provide a comprehensive team with the full ability to 
                            get the work done within the agreed upon timeline. IT4E works with the client project 
                            manager to structure the project schedule or otherwise address the body of requirements 
                            to incrementally deliver the full set of requirements. We also have experience in many
                            different project management methodologies, including certification in agile SCRUM.
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Best practices</span><br />
                            Using Project Management Institute (PMI) best practices, IT4E has a proven process for 
                            managing all aspects of the project life cycle to mitigate time, scope, budget, and 
                            quality risks inherent in IT projects. IT4E has developed the tools that support each 
                            phase of the project/task order and uses these tools to document, track, and resolve 
                            issues that may occur. Most importantly, IT4E teams are fully committed to performing 
                            their best technical work and keeping open communication with our clients at all points 
                            in the project. 
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="check" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectManagementComponent;
