import React, {Component} from 'react';
import '../../Styles/Solutions.scss';
import bg from '../../Images/sapHANABackground.png';
import Parallax from "../../Components/Parallax";
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sapBadge from '../../Images/sapCertificationBadge.png';
import sapBadge2 from '../../Images/sapCertificationBadge2.png';

class SAPHANAComponent extends Component {
    render() {
        return (
            <div className="solution-container">
                <Parallax img={bg}>
                    <div className="solution-bar">
                        <h2>SAP HANA</h2>
                        IT4E consultants use SAP HANA 2.0 to provide clients with a wide range of capabilities to manage data from start to finish.  
                    </div>
                </Parallax>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="chart-line" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Predictive Analytics</span><br />
                            The goal of the first SAP HANA project was to provide a high-performance platform on which data scientists built 
                            business analytics applications. SAP HANA Studio was the primary tool used to interact with the SAP HANA system. 
                            Transformations implemented in SAP DATA Services and SAP HANA SQLScript provided data cleansing and validation.  
                            Procedures in the SAP HANA Predictive Analysis Library (PAL) were called to perform analytic algorithms, such as 
                            auto regressive integrated moving average (ARIMA) and logistic regression, and the results were used to produce 
                            reports and insights needed to solve business problems.  SAP Lumira was used to create storyboards.  Storyboards 
                            were published for easy access by authorized users throughout the enterprise using SAP BusinessObjects BI Platform. 
                            Business subject areas in the scope of this project included supply chain management, employee attrition analysis 
                            and predictive maintenance. 
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Database Applications</span><br />
                            The goal of the second SAP HANA project was to develop a new line of business application using a 3-tier 
                            architecture, with SAP HANA providing the data service tier.  SAP HANA WebIDE was the primary tool used to interact 
                            with the SAP HANA system, and source code control was provided by an internal Git server.  A multi-target 
                            application (MTA) project containing 3 HANA database modules, one for each database schema required by the 
                            application, was developed.  Tables, data types, views, stored procedures, synonyms, sequences, functions and other 
                            database artifacts were all defined using Core Data Services (CDS) documents and deployed using the HANA Deployment 
                            Infrastructure (HDI). HANA system versioning capabilities were configured on most tables.  Full text indexes 
                            including column ranking were defined in the CDS documents for tables and views where the application required a 
                            full text search capability.
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="database" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="tablet-alt" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Multi-target Web Applications</span><br />
                            Currently we are working on a native XSA HANA application for timesheet management that has some unique requirements 
                            not found in commercially-available timesheet applications and services.  The application is structured as an SAPUI5 
                            HTML5 Module for the presentation layer, a Node.js Module that provides CRUD operations using OData Version 4,  and 
                            an SAP HANA Database Module that provides the backend data service.  The SAPUI5 libraries generate a responsive user 
                            experience for desktop, tablet and phone devices with a common code base.  The 3 modules are all versioned and 
                            deployed as a single Multi-Target Application (MTA).  The platform being used for application development is SAP HANA 
                            Express Edition, and the production target is the SAP Cloud Platform. 
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Training</span><br />
                            IT4E is committed to providing the best available SAP HANA training for consultants. The training covers a wide variety 
                            of relevant topics that prepare consultants to provide the best service for clients. Training is obtained through 
                            official SAP training courses and materials and research projects in the SAP HANA development lab at IT4E headquarters. 
                            The topics included modeling, native application development, administration, security and SAP Lumira.  We plan to 
                            continue this rigorous training program in 2020 to ensure that IT4E consultants are always properly prepared and 
                            qualified to provide maximum value to our clients.   
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="chalkboard-teacher" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn textAlignCenter">
                        <Fade duration={3500}>
                            <img src={sapBadge} className="sapBadges" />
                            <img src={sapBadge2} className="sapBadges" />
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Certification</span><br />
                            As an additional measure of competence, IT4E consultants have earned multiple SAP HANA certifications.  Information about 
                            the SAP certification can be found on the SAP Education site.  The certifications currently held are represented by the 
                            following badges awarded by SAP. 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SAPHANAComponent;
