import React, {Component} from 'react';
import {Nav, NavLink, withRouter} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import ContactForm from "../Components/ContactForm";
import '../Styles/HomeComponent.scss';
import Parallax from "../Components/Parallax";
import BannerImage from '../Images/banner-1.jpg';
import logoImage from '../Images/it4eLogoBasicTransparent.png';
import goldLogoImage from '../Images/it4elogo.png';
import summaryBG from '../Images/summaryBackground.png';
import ourServicesBG from '../Images/ourServices.png';
import businessAnalyticsIcon from '../Images/businessAnalyticsIcon.png';
import sapHANABG from '../Images/sapHANABackground.png';
import sapHANAIcon from '../Images/sapHANAIcon.png';
import databaseManagementIcon from '../Images/databaseManagementIcon.png';
import sharePointIcon from '../Images/sharePointIcon.png';
import webDevelopmentIcon from '../Images/webDevelopmentIcon.png';
import projectManagementIcon from '../Images/projectManagementIcon.png';
import contactBG from '../Images/contact.jpg';

class HomeComponent extends Component {
    render() {
        return (
            <div id="home-container">
                <Parallax img={BannerImage}/>
                <div>
                    <div className="home-header">
                        <img src={goldLogoImage} className="it4eLogo" /><br /><br />
                        <span>Your Business, Your Data, Your Vision</span>
                    </div>
                    <div id="home-about-container">
                        <Parallax img={summaryBG}>
                            <div id="about-bar" className="home-bar">
                                <div className="columns col-gapless">
                                    <div className="column col-6 col-sm-12">
                                        <h4>IT4E specializes in helping organizations of all<br />
                                        sizes leverage their own data to make<br />
                                        optimal data-driven decisions that<br />
                                        lead to success.</h4>
                                    </div>
                                    <div className="column col-6 col-sm-12 textAlignRight">
                                        <span className="contentFloatRight contentSubTag">We've been helping companies reach success with their<br /> 
                                            projects for over 15 years, and we can<br />
                                            help you too.
                                        </span><br /><br />
                                        <button className="btn">
                                            <NavLink to='/about'>Learn more</NavLink>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Parallax>
                    </div>
                    <div className="home-header gold">
                        <h1>Our Services</h1>
                    </div>
                    <div id="home-solutions-container">
                        <Parallax img={ourServicesBG}>
                            <div className="home-bar home-solutions-bar">
                            <Fade left>
                                <div className="columns col-gapless">
                                    <div className="column col-4 col-sm-12 homeContentColumn">
                                        <ul id="grid" className="clr transformedGrid">
                                            <li className="liLeft">
                                                <a href="/business-analytics">
                                                    <div className="hexagon">
                                                        <img src={businessAnalyticsIcon} alt="Lorem Ipsum"/>
                                                        <span>Business Analytics</span>
                                                        <div className="overlay"></div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="column col-8 col-sm-12 homeContentColumn">
                                        <div className="homeInnerContentColumn">
                                            <span className="categoryTitle">Business Analytics</span><br />
                                            Business Analytics is the use of various statistical methods and mathematical algorithms 
                                            on real data to better illustrate the state of the business and to direct better decision making. 
                                        </div>
                                    </div>
                                </div>
                                </Fade>
                            </div>
                            <div className="home-bar home-solutions-bar">
                                <Fade right>
                                    <div className="columns col-gapless">
                                        <div className="column col-8 col-sm-12 homeContentColumn">
                                            <div className="homeInnerContentColumn">
                                                <span className="categoryTitle">SAP HANA 2.0</span><br />
                                                SAP HANA is an in-memory relational database management system. 
                                                It allows consumers to effectively manage data in all respects, 
                                                from data collection through interpretable, data-driven results.   
                                            </div>
                                        </div>
                                        <div className="column col-4 col-sm-12 homeContentColumn">
                                            <ul id="grid" className="clr transformedGrid">
                                                <li className="liRight">
                                                    <a href="/sap-HANA">
                                                        <div className="hexagon">
                                                            <img src={sapHANAIcon} alt="Lorem Ipsum"/>
                                                            <span>SAP HANA 2.0</span>
                                                            <div className="overlay"></div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="home-bar home-solutions-bar">
                                <Fade left>
                                    <div className="columns col-gapless">
                                        <div className="column col-4 col-sm-12 homeContentColumn">
                                            <ul id="grid" className="clr transformedGrid">
                                                <li className="liLeft">
                                                    <a href="/database-management">
                                                        <div className="hexagon">
                                                            <img src={databaseManagementIcon} alt="Lorem Ipsum"/>
                                                            <span>Database Management</span>
                                                            <div className="overlay"></div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="column col-8 col-sm-12 homeContentColumn">
                                            <div className="homeInnerContentColumn">
                                                <span className="categoryTitle">Database Management</span><br />
                                                Database Management is the practice of organizing, storing, 
                                                and retrieving data from a digital repository of information.
                                                Database Managers systematically interact with the database to
                                                offer information their consumers require.
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="home-bar home-solutions-bar">
                                <Fade right>
                                    <div className="columns col-gapless">
                                        <div className="column col-8 col-sm-12 homeContentColumn">
                                            <div className="homeInnerContentColumn">
                                                <span className="categoryTitle">SharePoint</span><br />
                                                SharePoint is Microsoft's all-consuming solution for content
                                                management and team collaboration that integrates with
                                                Microsoft Office. It is highly configurable and used across
                                                organizations large and small.
                                            </div>
                                        </div>
                                        <div className="column col-4 col-sm-12 homeContentColumn">
                                            <ul id="grid" className="clr transformedGrid">
                                                <li className="liRight">
                                                    <a href="/sharePoint">
                                                        <div className="hexagon">
                                                            <img src={sharePointIcon} alt="Lorem Ipsum"/>
                                                            <span>SharePoint</span>
                                                            <div className="overlay"></div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="home-bar home-solutions-bar">
                                <Fade left>
                                    <div className="columns col-gapless">
                                        <div className="column col-4 col-sm-12 homeContentColumn">
                                            <ul id="grid" className="clr transformedGrid">
                                                <li className="liLeft">
                                                    <a href="/web-development">
                                                        <div className="hexagon">
                                                            <img src={webDevelopmentIcon} alt="Lorem Ipsum"/>
                                                            <span>Web Development</span>
                                                            <div className="overlay"></div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="column col-8 col-sm-12 homeContentColumn">
                                            <div className="homeInnerContentColumn">
                                                <span className="categoryTitle">Web Development</span><br />
                                                Web development is the practice of creating web sites and
                                                applications. These sites can be public facing or for a company's 
                                                own internal purposes. They range from simple text-based
                                                pages to complex business applications.
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="home-bar home-solutions-bar">
                                <Fade right>
                                    <div className="columns col-gapless homeColumns">
                                        <div className="column col-8 col-sm-12 homeContentColumn">
                                            <div className="homeInnerContentColumn">
                                                <span className="categoryTitle">Project Management</span><br />
                                                Project Management is the practice of controlling the life cycle
                                                of a well-defined scope of work from start to finish. A project
                                                manager can ensure the successful completion of all project goals.
                                            </div>
                                        </div>
                                        <div className="column col-4 col-sm-12 homeContentColumn">
                                            <ul id="grid" className="clr transformedGrid">
                                                <li className="liRight">
                                                    <a href="/project-management">
                                                        <div className="hexagon">
                                                            <img src={projectManagementIcon} alt="Lorem Ipsum"/>
                                                            <span>Project Management</span>
                                                            <div className="overlay"></div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </Parallax>
                    </div>
                    <div className="home-header gold">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="contact-columns">
                        <div className="contact-column contact-image">
                            <Parallax img={contactBG}/>
                        </div>
                        <div className="contact-column contact-column--form">
                            <div className="contact-form-container">
                                <div className="contact-header">
                                    <p>Got a question? We would love to hear from you. Send us a message and we will respond
                                        as soon as possible.</p>
                                </div>
                                <ContactForm sendTo="info@it4e.com"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeComponent;
