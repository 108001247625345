import React, {Component} from 'react';

class TrainingComponent extends Component {
    render() {
        return (
            <div className="container">
                <h4>Training</h4>
                <div className="columns">
                    <div className="column col-8">col-8</div>
                    <div className="column col-3">col-3</div>
                    <div className="column col-4">col-4</div>
                    <div className="column col-1">col-1</div>
                </div>
            </div>
        );
    }
}

export default TrainingComponent;
