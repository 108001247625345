import React from 'react';
import '../Styles/Contact.scss';

const ContactInformation = ({location}) => {

    return (
        <div className="contact-form-container">
            {
                location &&
                <div className="contact-header">
                    <h2>Location</h2>
                    <div className="divider purple"/>
                </div>
            }
            <div className="contact-info">
                <i className="icon icon-location"/>
                <div>
                    123 Center Park Drive, Suite #103
                    Knoxville, Tennessee 37922
                </div>
            </div>
            <div className="contact-info">
                <i className="icon icon-mail"/>
                <div>
                    <a href="mailto:info@it4e.com" target="_blank">info@it4e.com</a>
                </div>
            </div>
            <div className="contact-info">
                <i className="icon icon-message"/>
                <div>
                    (865) 392-5250
                </div>
            </div>
        </div>
    );
};

export default ContactInformation;
