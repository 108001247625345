import React from 'react';

import '../Styles/ContactForm.scss';

const ContactForm = (props) => {
    const {sendTo} = props;
    const actionUrl = `https://formspree.io/${sendTo}`;

    return (
        <form className="contact-form" action={actionUrl} method="POST">
            <div className="form-group">
                <div className="col-12">
                    <label className="form-label" htmlFor="input-name"><strong>Name</strong></label>
                    <input name="name" className="form-input" type="text" id="name"/>
                </div>
            </div>
            <div className="form-group">
                <div className="col-12">
                    <label className="form-label" htmlFor="input-email"><strong>Email</strong></label>
                    <input name="email" className="form-input" type="text" id="email"/>
                </div>
            </div>
            <div className="form-group">
                <div className="col-12">
                    <label className="form-label" htmlFor="input-message"><strong>Message</strong></label>
                    <textarea name="message" className="form-input" id="input-message" rows="3"/>
                </div>
            </div>
            <button type="submit" className="btn btn-contact">Send Message</button>
        </form>
    );
};

export default ContactForm;
