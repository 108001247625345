import React, {Component} from 'react';
import {Nav, NavLink, withRouter} from 'react-router-dom';

import '../Styles/NavbarComponent.scss';
import Logo from '../Images/it4elogo.png';
import sapBadge from '../Images/sapCertificationBadge.png';
import sapBadge2 from '../Images/sapCertificationBadge2.png';

class NavBarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidenavClasses: 'sidenav nav'
        }
    }

    changeSidenavState = (expanded) => {
        this.setState({sidenavClasses: expanded ? 'sidenav nav expanded' : 'sidenav nav'})
    };

    render() {
        const {history} = this.props;
        return (
            <header>
                <div className="sticky-navbar">
                    <ul className="large-nav">
                        <li className="item">
                            <NavLink exact={true} activeClassName='active' to='/'>Home</NavLink>
                        </li>

                        <li className="item">
                            <NavLink activeClassName='active' to='/about'>The Team</NavLink>
                        </li>

                        <li className="item">
                            <NavLink activeClassName='active' to='/solutions'>Our Solutions</NavLink>
                        </li>

                        <li className="item">
                            <NavLink activeClassName='active' to='/contact'>Contact Us</NavLink>
                        </li>
                    </ul>


                    <div className="logo">
                        <img src={Logo} className="logoImg" />
                        <img src={sapBadge} className="sapLogo thumbnail" />
                        <img src={sapBadge2} className="sapLogo thumbnail" />
                        <span className="hide-on-xs">
                        </span>
                    </div>
                    <span className="nav-menu" onClick={() => this.changeSidenavState(true)}>
                        <i className="icon icon-2x icon-menu"/>
                    </span>
                </div>
                <ul className={this.state.sidenavClasses}>
                   <span className="close-menu" onClick={() => this.changeSidenavState(false)}>
                        <i className="icon icon-2x icon-cross"/>
                    </span>
                    <li className="nav-item">
                        <NavLink exact={true} activeClassName='active' to='/' onClick={() => this.changeSidenavState(false)}>Home</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink activeClassName='active' to='/about' onClick={() => this.changeSidenavState(false)}>The Team</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink activeClassName='active' to='/solutions' onClick={() => this.changeSidenavState(false)}>Our Solutions</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink activeClassName='active' to='/contact' onClick={() => this.changeSidenavState(false)}>Contact Us</NavLink>
                    </li>
                </ul>
            </header>
        );
    }
}

export default withRouter(NavBarComponent);
