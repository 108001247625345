import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import './Styles/App.scss';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faSitemap, faCloud, faUsers, 
    faChartLine, faTabletAlt, faChalkboardTeacher, faTachometerAlt, 
    faRobot, faCodeBranch, faSnowboarding,
    faCode, faShare, faMobileAlt, faDove, faProjectDiagram, faCheck,
    faLaptopCode, faChartBar } from '@fortawesome/free-solid-svg-icons'

import HomeComponent from "./Pages/HomeComponent";
import NavBarComponent from "./Components/NavBarComponent";
import SolutionsComponent from "./Pages/SolutionsComponent";
import BusinessAnalyticsComponent from "./Pages/Solutions/BusinessAnalyticsComponent";
import SAPHANAComponent from "./Pages/Solutions/SAPHANAComponent";
import DatabaseManagementComponent from "./Pages/Solutions/DatabaseManagementComponent";
import SharePointComponent from "./Pages/Solutions/SharePointComponent";
import WebDevelopmentComponent from "./Pages/Solutions/WebDevelopmentComponent";
import ProjectManagementComponent from "./Pages/Solutions/ProjectManagementComponent";
import ProjectsComponent from "./Pages/ProjectsComponent";
import AboutComponent from "./Pages/AboutComponent";
import TrainingComponent from "./Pages/TrainingComponent";
import ContactComponent from "./Pages/ContactComponent";
import FooterComponent from "./Components/FooterComponent";
import ScrollToTop from './Components/ScrollToTop';

library.add(faDatabase, faSitemap, faCloud, faUsers, faChartLine, faTabletAlt, faChalkboardTeacher, 
    faTachometerAlt, faRobot, faCodeBranch, faSnowboarding, faCode, faShare, faMobileAlt, faDove, 
    faProjectDiagram, faCheck, faLaptopCode, faChartBar )

// CSS Library docs: https://picturepan2.github.io/spectre/getting-started/installation.html
class App extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <NavBarComponent/>
                    <div className="App">
                        <div>
                            <Route exact path="/" component={HomeComponent}/>
                            <Route exact path="/solutions" component={SolutionsComponent}/>
                            <Route exact path="/projects" component={ProjectsComponent}/>
                            <Route exact path="/about" component={AboutComponent}/>
                            <Route exact path="/training" component={TrainingComponent}/>
                            <Route exact path="/contact" component={ContactComponent}/>
                            <Route exact path="/business-analytics" component={BusinessAnalyticsComponent}/>
                            <Route exact path="/sap-HANA" component={SAPHANAComponent}/>
                            <Route exact path="/database-management" component={DatabaseManagementComponent}/>
                            <Route exact path="/sharePoint" component={SharePointComponent}/>
                            <Route exact path="/web-development" component={WebDevelopmentComponent}/>
                            <Route exact path="/project-management" component={ProjectManagementComponent}/>
                        </div>
                    </div>
                    <FooterComponent/>
                </ScrollToTop>
            </Router>
        );
    }
}

export default App;
