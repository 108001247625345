import React, {Component} from 'react';
import '../../Styles/Solutions.scss';
import bg from '../../Images/sharePointBackground.png';
import Parallax from "../../Components/Parallax";
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SharePointComponent extends Component {
    render() {
        return (
            <div className="solution-container">
                <Parallax img={bg}>
                    <div className="solution-bar">
                        <h2>SharePoint</h2>
                        IT4E began offering SharePoint services in 2004. Our consultants have helped dozens of clients across the United States launch 
                        web-based collaborative platforms to organize and enhance their businesses. We have provided SharePoint services ranging from 
                        installation, configuration, and administration to customized web solutions.
                    </div>
                </Parallax>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="tachometer-alt" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Custom Application Development</span><br />
                            One of our recent projects involves the delivery of business intelligence dashboards as a SharePoint application. The user 
                            interface is implemented with custom web parts that are reused across several dashboard sites for different business units.  
                            We developed the graphics and other presentation elements without any out-of-the-box business graphics libraries that provide 
                            common elements such as line charts, pie charts and so forth.  This is just one example of many custom SharePoint 
                            applications created by IT4E.
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Migration</span><br />
                            IT4E has led dozens of Microsoft Office 365 migration projects – typically migrations from on-premise Exchange/SharePoint environments to the Microsoft Office 365 cloud-based infrastructure. We involve ourselves in analyzing clients’ current content to determine how they can develop a taxonomy to streamline business processes. IT4E has developed and refined a proven, documented methodology to ensure successful migrations and enable subsequent enhancements within their SharePoint platform.  
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="dove" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="chalkboard-teacher" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Training</span><br />
                            IT4E has taught Microsoft Official Curriculum courses covering application development and administration of SharePoint for over twelve years. From its roots in training, the entire team strives to impart as much knowledge and subject matter expertise as possible so that IT4E clients derive lasting value from our consulting services.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SharePointComponent;
