import React, {Component} from 'react';
import '../../Styles/Solutions.scss';
import bg from '../../Images/webDevelopmentBackground.png';
import Parallax from "../../Components/Parallax";
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class WebDevelopmentComponent extends Component {
    render() {
        return (
            <div className="solution-container">
                <Parallax img={bg}>
                    <div className="solution-bar">
                        <h2>Web Development</h2>
                        The user experience on almost all of the system and application development work that we 
                        have done for many years has been browser-based, giving us a strong background in web 
                        development. 
                    </div>
                </Parallax>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="laptop-code" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Web technologies</span><br />
                            Although we have expertise in basic web technologies, our competence extends far beyond 
                            that. Our recent applications have required a responsive user experience that adapts 
                            depending on the device used to view it, and we have used well-known JavaScript 
                            libraries such as Angular and React to develop them. After Microsoft introduced the 
                            .NET Framework, we used the ASP.NET server controls in many of our projects to create 
                            the user interface and bind application data to server-side controls. In the 
                            SharePoint space, we have developed many custom web parts using a combination of ASP.NET 
                            server controls and custom HTML, CSS, and JavaScript, all delivered as SharePoint 
                            solutions.  
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Web BI Applications</span><br />
                            Several of our recent and ongoing projects involve custom business intelligence 
                            applications built and deployed as SharePoint solutions.  Because our client’s SharePoint 
                            infrastructure is currently based on SharePoint 2010, we developed standard web parts 
                            (classes that inherit from the ASP.NET web part class), and we package the web parts and 
                            SharePoint farm solutions that are deployed by the SharePoint administrator.  Our web 
                            part logic retrieves data needed for the charts and graphs and delivers it to the page, 
                            where client-side logic uses it to draw the charts.   
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="chart-bar" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="cloud" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Web SAP HANA XSA applications</span><br />
                            We are also developing XSA native applications on an SAP HANA 2.0 SPS03 platform, both 
                            for on-premise and SAP Cloud Platform deployments. These applications contain HANA 
                            database modules, Node.js modules, and SAPUI5 modules. One such application is a 
                            timesheet entry and maintenance application designed to meet some specialized business 
                            requirements not available in commercial off-the-shelf products and services. We are 
                            also developing a teaching tool for presenting the analytical capabilities of SAP HANA, 
                            and it uses a Fiori Launchpad to provide navigation to applications in different 
                            analytical subject areas.  Both applications leverage the responsive capabilities of 
                            SAPUI5 to create a great user experience on desktop, tablet and phone devices.  
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WebDevelopmentComponent;
