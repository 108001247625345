import React, {Component} from 'react';
import '../Styles/Solutions.scss'
import imageBusinessAnalytics from '../Images/businessAnalyticsIcon.png';
import imageSAPHANA from '../Images/sapHANAIcon.png';
import imageDatabaseManagement from '../Images/databaseManagementIcon.png';
import imageSharePoint from '../Images/sharePointIcon.png';
import imageWebDevelopment from '../Images/webDevelopmentIcon.png';
import imageProjectManagement from '../Images/projectManagementIcon.png';
import bg from '../Images/solutionsBackground.jpg';
import Parallax from '../Components/Parallax';

class SolutionsComponent extends Component {
    render() {
        return (
            <div className="solution-container">
                <Parallax img={bg}>
                    <div className="solution-bar">
                    <h2>Our Solutions</h2>
                        IT4E provides a wide variety of solutions to your company's unique
                        business needs at a professional level that can't be beat.
                    </div>
                </Parallax>
                <div>
                    <ul id="grid" className="clr">
                        <li>
                            <a href="/business-analytics">
                                <div className="hexagon">
                                    <img src={imageBusinessAnalytics} alt="Lorem Ipsum"/>
                                    <span>Business Analytics</span>
                                    <div className="overlay"></div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/sap-HANA">
                                <div className="hexagon">
                                    <img src={imageSAPHANA} alt="Lorem Ipsum"/>
                                    <span>SAP HANA 2.0</span>
                                    <div className="overlay"></div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/database-management">
                                <div className="hexagon">
                                    <img src={imageDatabaseManagement} alt="Lorem Ipsum"/>
                                    <span>Database Management</span>
                                    <div className="overlay"></div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/sharePoint">
                                <div className="hexagon">
                                    <img src={imageSharePoint} alt="Lorem Ipsum"/>
                                    <span>SharePoint</span>
                                    <div className="overlay"></div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/web-development">
                                <div className="hexagon">
                                    <img src={imageWebDevelopment} alt="Lorem Ipsum"/>
                                    <span>Web Development</span>
                                    <div className="overlay"></div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/project-management">
                                <div className="hexagon">
                                    <img src={imageProjectManagement} alt="Lorem Ipsum"/>
                                    <span>Project Management</span>
                                    <div className="overlay"></div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default SolutionsComponent;
