import React, {Component} from 'react';
import '../../Styles/Solutions.scss';
import bg from '../../Images/businessAnalyticsBackground.png';
import Parallax from "../../Components/Parallax";
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class BusinessAnalyticsComponent extends Component {
    render() {
        return (
            <div className="solution-container">
                <Parallax img={bg}>
                    <div className="solution-bar">
                        <h2>Business Analytics</h2>
                        Using tools such as R, Python, and SAP HANA, IT4E consultants construct data driven support systems that dictate better business decisions. 
                    </div>
                </Parallax>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="tachometer-alt" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Dashboards</span><br />
                            It’s your data, you should see it how you want to see it. 
                            Data summaries in dashboards keep end-users current on what’s happening in their organizations. 
                            Dashboards are compatible with computers and mobile devices. 
                            IT4E has used reporting tools such as, Tableau, SAP Lumira and Business Objects, and more.    
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Machine Learning</span><br />
                            Wouldn’t business be easier if you had an idea what the future held? 
                            Machine Learning techniques use actual data and mathematical and statistical algorithm to estimate future events. 
                            IT4E has experience using algorithns such as Regression and Classification models, Clustering, Time Series, Text 
                            Mining, and Survival Analysis. 
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="robot" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="code-branch" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Decision Optimization</span><br />
                            Want to get the most out of your resources? With decision optimization techniques, 
                            IT4E lets you know how you can make the best possible data driven decision in a variety of scenarios. 
                            These methods are commonly seen in Supply Chain, Finance, Scheduling, and more. 
                        </div>
                    </div>
                </div>
                <div className="columns col-gapless solutionColumns">
                    <div className="column col-8 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                            <span className="categoryTitle">Actionable Results</span><br />
                            All intensive data programming performed is in vein if there’s no plan of action. 
                            IT4E consultants have experience taking programming results and translating them into a business plan. 
                            The team delivers interpretable results in reports and presentations. 
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 solutionsContentColumn">
                        <div className="solutionsInnerContentColumn">
                        <Fade duration={3500}>
                            <FontAwesomeIcon icon="snowboarding" size="6x"/>
                        </Fade>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BusinessAnalyticsComponent;
